<template>
  <template v-if="readOnly">
    <p class="text-white">{{ getTextLabel(valueSelect) }}</p>
  </template>
  <div v-else class="relative">
    <a-select
      v-model:value="valueSelect"
      :mode="mode"
      style="width: 100%"
      class="select-search-list"
      :placeholder="placeholder"
      :maxTagCount="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      :options="listOption"
      :allowClear="allowClear"
      :show-search="isShowSearch"
      :filter-option="filterOption"
      size="medium"
      @change="change"
      :disabled="disabled"
    ></a-select>
    <cds-feather-icons
      v-if="multiple && valueSelect.length === 0"
      type="chevron-down"
      class="text-ems-gray400 absolute right-2 inline-flex items-center h-full"
    />
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import { getText } from '@/util/common-utils';
import _ from 'lodash';
import { i18n } from '@/main';

export default defineComponent({
  name: 'SelectSearchList',
  props: {
    options: VueTypes.array.def([]),
    labelProp: VueTypes.string.def('label'),
    valueProp: VueTypes.string.def('value'),
    maxTagCount: VueTypes.number.def(3),
    maxTagTextLength: VueTypes.number.def(30),
    multiple: VueTypes.bool.def(false),
    placeholder: VueTypes.string,
    allowClear: VueTypes.bool.def(false),
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.number,
      VueTypes.array,
    ]),
    readOnly: VueTypes.bool.def(false),
    isAll: VueTypes.bool.def(false),
    isLabelI18n: VueTypes.bool.def(true),
    isShowSearch: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const { t } = i18n.global;
    const listOption = computed(() => {
      const data = _.cloneDeep(props.options);
      if (props.isAll) {
        data.unshift({
          value: null,
          label: 'All',
        });
      }
      return data.map((e) => ({
        label: props.isLabelI18n ? t(e[props.labelProp]) : e[props.labelProp],
        value: e[props.valueProp],
        disabled: e.disabled,
      }));
    });
    const valueSelect = ref(props.value);
    const change = (val) => {
      emit('update:value', val);
      emit('change');
    };
    const mode = computed(() => (props.multiple ? 'multiple' : null));
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const getTextLabel = computed(
      () => (value) => t(getText(value, listOption.value))
    );
    const filterOption = (input, option) => {
      return (
        option.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            input
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) >= 0
      );
    };
    return {
      valueSelect,
      listOption,
      change,
      mode,
      getTextLabel,
      filterOption,
    };
  },
});
</script>
<style>
.select-search-list {
  height: 39px;
  width: 100%;
}
.select-search-list .ant-select-selector {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}
.select-search-list .ant-select-selector:hover {
  border: solid 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
}
.select-search-list .ant-select-selector .ant-select-selection-search {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}
.select-search-list .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100% !important;
}
.select-search-list .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
  border: solid 1px !important;
  box-shadow: none;
  --tw-border-opacity: 1 !important;
  border-color: rgba(85, 85, 85, var(--tw-border-opacity)) !important;
}
.select-search-list .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 39px !important;
  padding-left: 6px !important;
}
.select-search-list .ant-select-multiple .ant-select-selector .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.select-search-list .ant-select-multiple
    .ant-select-selector
    .ant-select-selection-item
    .ant-select-selection-item-remove {
  padding-left: 5px !important;
}
.select-search-list .ant-select-arrow, .select-search-list .ant-select-selection-item, .select-search-list .ant-select-dropdown .ant-empty-description {
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.select-search-list .ant-select-item-option-content {
  --tw-text-opacity: 1 !important;
  color: rgba(196, 196, 196, var(--tw-text-opacity)) !important;
}
.select-search-list .ant-select-dropdown {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 31, 31, var(--tw-bg-opacity));
}
.select-search-list .ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 38, 38, var(--tw-bg-opacity));
}
.select-search-list .ant-select-dropdown
    .ant-select-item.ant-select-item-option-selected
    .ant-select-item-option-content {
  padding-left: 0;
}
.select-search-list .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(123, 123, 123, var(--tw-text-opacity)) !important;
  position: relative !important;
  line-height: 39px !important;
  padding-left: 6px !important;
}
.select-search-list .ant-select-multiple .ant-select-selector .ant-select-selection-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgba(123, 123, 123, var(--tw-text-opacity)) !important;
}
.select-search-list .ant-select-selection-search-input {
  height: 39px !important;
  line-height: 39px !important;
  padding-left: 6px !important;
  --tw-text-opacity: 1;
  color: rgba(196, 196, 196, var(--tw-text-opacity));
}
.select-search-list .ant-select-selection-search-mirror {
  padding-left: 6px !important;
}
.select-search-list .ant-select-clear {
  right: 30px;
}
.select-search-list .ant-select-clear span {
  vertical-align: top;
}
.select-search-list .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 34px !important;
  transform: translateY(0) !important;
  padding-right: 10px;
}
.select-search-list .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 85, 85, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.select-search-list .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.select-search-list .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.select-search-list.ant-select-multiple .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.5rem;
}
.select-search-list.ant-select-multiple .ant-select-selection-placeholder {
  left: 17px !important;
}
</style>
