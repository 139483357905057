<template>
  <cds-cards>
    <a-row>
      <a-col class="text-center" v-for="user in users" :key="user.id" :xxl="6" :xl="6" :lg="6" :md="8" :xs="24">
        <a-avatar :size="200"
                  :src="user.avatar">
        </a-avatar>
        <p class="mt-2" style="font-weight: bold">{{user.username}}</p>
      </a-col>
    </a-row>
  </cds-cards>
</template>

<script>
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  setup() {
    const {state} = useStore();
    const users = computed(() => state.userGroup.users);
    return {
      users,
    }
  }
});
</script>

<style scoped>



</style>
