<template>
  <template v-if="readOnly">
    {{ valueInput }}
  </template>
  <div v-else class="input-search relative flex gap-[21px] items-center">
    <img
      src="@/static/img/input-search2.png"
      alt="drawer-bg"
      class="min-w-[240px] max-w-[240px]"
    />

    <div
      class="absolute top-[20%] translate-y-[-50%] left-0 bg-transparent px-3 text-main-1 focus:outline-none min-w-[240px]"
    >
      <a-input
        class="w-100 ant-input-sm"
        v-model:value="valueInput"
        :maxlength="maxlength"
        :show-count="showCount"
        :size="size"
        :iconName="iconName"
        :placeholder="placeholder"
        :allowClear="allowClear"
        :disabled="disabled"
        autoComplete="off"
        @input="onInput"
        @change="onChange"
        @blur="onBlur"
        @press-enter="pressEnter"
      >
        <template #prefix>
          <cds-feather-icons type="search" size="14" />
        </template>
      </a-input>
    </div>
    <ClipButton
      v-if="hasSearchAdvanced"
      type="secondary"
      @click="onSearchAdvanced"
      class="text-sm h-[40px] whitespace-nowrap"
      >{{ $t('advanced_search') }}</ClipButton
    >
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';
import ClipButton from '@/components/buttons/ClipButton.vue';

export default defineComponent({
  name: 'Input',
  props: {
    value: VueTypes.string.def(''),
    placeholder: VueTypes.string,
    maxlength: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    showCount: VueTypes.bool.def(false),
    allowClear: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    icon: VueTypes.string,
    iconName: VueTypes.string,
    readOnly: VueTypes.bool.def(false),
    hasSearchAdvanced: VueTypes.bool.def(false),
  },
  components: {
    ClipButton,
  },
  emits: ['press-enter', 'onSearchAdvanced', 'change'],
  setup(props) {
    const valueInput = ref(props.value);
    return {
      valueInput,
    };
  },
  methods: {
    iconClick() {
      this.$emit('iconClick', this.iconName);
    },
    onInput(e) {
      this.valueInput = e.target.value
        ? (e.target.value + '').trimLeft()
        : e.target.value;
      this.$emit('update:value', this.valueInput);
    },
    onBlur() {
      this.$emit(
        'update:value',
        this.valueInput ? (this.valueInput + '').trim() : this.valueInput
      );
    },
    pressEnter() {
      this.$emit('press-enter');
    },
    onChange() {
      this.$emit('change');
    },
    onSearchAdvanced() {
      this.$emit('onSearchAdvanced');
    },
  },
  watch: {
    value(val) {
      this.valueInput = val;
    },
  },
});
</script>

<style lang="scss">

.input-search {
  background: transparent;
  border-radius: unset;
  border: unset !important;

  input.ant-input.ant-input-sm {
    background-color: transparent;
    color: rgba(123, 123, 123, 1);
  }

  .ant-input-sm {
    padding: 6px 12px;
    border-radius: 0;
    border: solid 1px !important;
    line-height: 25px;
    --tw-bg-opacity: 1;
    --tw-border-opacity: 1 !important;
    background: transparent !important;
    border: unset !important;
    input {
      &::placeholder {
        color: #7b7b7b !important;
      }
    }
  }

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-sm.w-100.ant-input-sm {
    border: unset !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: unset;
    box-shadow: unset;
    border-right-width: unset;
    outline: 0;
  }
  .ant-input-affix-wrapper {
    padding: 4px 6px !important;
  }
}
.input-search.ant-input-sm {
  padding: 6px 0;
}

</style>
