<template>
  <div class="card-container">
    <a-tabs v-model:activeKey="activeKey" type="card" :tabPosition="tabPosition" @change="change">
      <slot></slot>
    </a-tabs>
  </div>
</template>
<script>
import {defineComponent, ref, watch} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  props: {
    tabPosition: VueTypes.oneOf(['top', 'right', 'bottom', 'left']).def('top'),
    data: VueTypes.arrayOf(VueTypes.object),
    active: VueTypes.arrayOf(VueTypes.number),
  },
  setup(props, { emit }) {
    const activeKey = ref(props.active)
    watch(
        () => props.active,
        (val) => (activeKey.value = val)
    );
    const change = (val) => emit('update:active', val);
    return {
      activeKey,
      change
    };
  },
});
</script>
<style>


</style>